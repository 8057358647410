import * as React from "react"
import { Link } from 'gatsby';

import Layout from "../components/Layout";
import Img from "react-cool-img";
import ErrorImg from "../../static/assets/404.jpeg"

const Error = props => {
    const { location } = props

    return <>
        <div className="totalContainer">
            <Layout classAdd="home" location={location}>
                <div className="errorBox">
                    <h1>Такой страницы нет !</h1>

                    <div className="errorBox__link">
                        <Link to="/">Вернуться на главную</Link>
                    </div>

                    <Img
                        style={ {width: '100%', marginTop: '40px', padding: '50px', borderRadius: '75px'}}
                        src={ErrorImg}
                        alt="404 error"
                    /> 
                </div>
            </Layout>
        </div>


    </>
}

export default Error;

